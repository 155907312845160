import React from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

import classnames from 'classnames/bind'

import { useBannerContext } from '../BannerProvider'
import CloseIcon from 'components/icons/CloseIcon'
import { RichText } from 'components/typo'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const Banner = ({ className, banner }) => {
  const [isBannerOpen, setIsBannerOpen] = useBannerContext()

  useIsomorphicLayoutEffect(() => {
    if (!isBannerOpen) {
      let store = JSON.parse(
        localStorage.getItem(process.env.BANNER_STORAGE_KEY)
      )

      if (store) store.display = false
      else
        store = {
          date: Date.parse(banner.publishDate),
          display: false,
        }

      localStorage.setItem(
        process.env.BANNER_STORAGE_KEY,
        JSON.stringify(store)
      )
    }
  }, [isBannerOpen])

  return (
    <div className={cx(css.banner, className, isBannerOpen && css.open)}>
      <RichText
        type="Text"
        text={banner.content}
        className={css.bannerRichText}
      >
        <span onClick={() => setIsBannerOpen(false)}>
          <CloseIcon color="white" />
        </span>
      </RichText>
    </div>
  )
}

Banner.defaultProps = {}

export default Banner
