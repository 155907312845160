import NodeCache from 'node-cache'

const cache = new NodeCache({ stdTTL: 10800 }) // 3 hours

const getUserProfileFromRepoUrl = (repoUrl) => {
  const profileUrl = repoUrl.split('/', 4).join('/')

  return `${profileUrl}.png`
}

const fetchGithubData = async () => {
  const cachedData = cache.get('githubStars')

  if (cachedData) {
    return cachedData
  }

  const githubResponse = await fetch(
    `https://api.github.com/repos/strapi/strapi`
  )
  const githubData = await githubResponse.json()

  cache.set('githubStars', githubData)

  return githubData
}

export { getUserProfileFromRepoUrl, fetchGithubData }
