import NodeCache from 'node-cache'
import spreadStrapiData from './spreadStrapiData'

// Initialize a cache to store fetched data
const cache = new NodeCache({ stdTTL: 300 })

/**
 * Fetches and caches data for a single Strapi content type.
 *
 * @param {string} path - The API path to fetch data from.
 * @param {boolean} preview - Flag to indicate if preview data should be fetched.
 * @return {Promise<object>} The fetched data.
 */
export default async function fetchSingleType(path, preview) {
  try {
    // Attempt to retrieve cached data for the given path
    const cachedData = cache.get(path)
    if (cachedData) {
      return spreadStrapiData(cachedData)
    }

    // Build the URL for the API request
    const url = new URL(`api/${path}`, process.env.API_URL)

    // Append query parameters for deep population and preview mode
    const queryParams = `${preview ? '&publicationState=preview' : ''}`
    const response = await fetch(`${url.href}${queryParams ? `?${queryParams}` : ``}`)

    if (!response.ok) {
      throw new Error(`Failed to fetch single type from Strapi (path=${path})`)
    }

    // Parse the JSON response
    const jsonData = await response.json()

    // Cache the fetched data for subsequent requests
    cache.set(path, jsonData)
    return spreadStrapiData(jsonData)
  } catch (error) {
    console.error(`Failed to fetch singletype path=${path}`, error)
  }
}
