import React, { createContext, useContext, useState } from 'react'

/**
 * The banner context.
 */
export const BannerProviderContext = createContext(null)

/**
 * The context wrapper element.
 *
 * @returns {React.ReactElement} Provider
 */
export function BannerProvider({ children, initialIsBannerOpen = false }) {
  const bannerProviderState = useState(initialIsBannerOpen)

  return (
    <BannerProviderContext.Provider value={bannerProviderState}>
      {children}
    </BannerProviderContext.Provider>
  )
}

/**
 * React state from the context.
 *
 * @returns {[boolean, React.Dispatch<React.SetStateAction<boolean>>]}
 */
export function useBannerContext() {
  return useContext(BannerProviderContext)
}
