import React, { useState, useRef, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

import classnames from 'classnames/bind'
import delve from 'dlv'
import Logo from 'components/shared/Logo'
import Layout from 'components/shared/Layout'
import Button from 'components/shared/Button'
import GithubButton from 'components/shared/GithubButton'
import Popin from 'components/shared/Popin'
import LogoPopin from 'components/shared/LogoPopin'
import useSpring from 'hooks/useSpring'
import usePrevious from 'hooks/usePrevious'
import useSetStyle from 'hooks/useSetStyle'
import { gsap } from 'gsap'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import HeaderBackground from '../HeaderBackground'
import NavigationLabel from './NavigationLabel'
import Panel from './Panel'
import { ReactComponent as Search } from '../../search/search-icon.svg'
import css from './styles.module.scss'
import { useHasScrolledContext } from '../../HasScrolledProvider'
import { useBannerContext } from '../../BannerProvider'
import SearchResults from 'components/shared/search/SearchResults'

const cx = classnames.bind(css)

const defaultBackgroundSpringConfig = {
  friction: 90,
  rigidity: 0.12,
}

const Desktop = ({
  className,
  logoPopin,
  panels,
  button,
  dark,
  customLogo,
  darkMode,
  isMarketplace,
  githubStars,
  isBlank,
  lowerLinks,
}) => {
  const [isBannerOpen] = useBannerContext()
  const [visiblePanel, setVisiblePanel] = useState()
  const [popinIsOpen, setPopinIsOpen] = useState(false)
  const previousVisiblePanel = usePrevious(visiblePanel)
  const hidePanel = useCallback(() => setVisiblePanel(null), [])
  const [hasScrolled] = useHasScrolledContext()
  const useDark = hasScrolled || dark
  const panelBackgroundRef = useRef()
  const panelBackgroundWrapperRef = useRef()
  const panelBackgroundContainerRef = useRef()
  const panelBackgroundGradientRef = useRef()
  const setPanelBackgroundWrapperStyle = useSetStyle(panelBackgroundWrapperRef)
  const setPanelBackgroundStyle = useSetStyle(panelBackgroundRef)
  const panelBackgroundContainerStyle = useSetStyle(panelBackgroundContainerRef)
  const [panelContextState, setPanelContextState] = useState({})
  const [searching, setSearching] = useState(false)

  const router = useRouter()
  // Access the current URL
  const currentUrl = router.asPath

  const setPanelRect = useCallback((id, wrapper, content) => {
    setPanelContextState((prevState) => {
      const newState = { ...prevState }
      newState[id] = { wrapper, content }

      return newState
    })
  }, [])

  const maxPanelHeight = Object.keys(panelContextState).reduce(
    (prevHeight, currentKey) => {
      const currentHeight = delve(
        panelContextState,
        `${currentKey}.wrapper.height`
      )

      return prevHeight > currentHeight ? prevHeight : currentHeight
    },
    0
  )

  const maxContentWidth = Object.keys(panelContextState).reduce(
    (prevWidth, currentKey) => {
      const currentWidth = delve(
        panelContextState,
        `${currentKey}.content.width`
      )

      return prevWidth > currentWidth ? prevWidth : currentWidth
    },
    0
  )

  const maxPanelHeightWithMarginForShadow = maxPanelHeight + 15

  const [setBgSpring] = useSpring({
    y: 0,
    x: 0,
    progress: 0,
    config: { interpolation: 'basic', friction: 5 },
    onUpdate: ({ y, progress }) => {
      const opacity = gsap.utils.normalize(0.3, 1, progress)

      setPanelBackgroundStyle({
        y,
        transformOrigin: 'top',
        opacity,
      })
      setPanelBackgroundWrapperStyle({
        visibility: opacity > 0 ? 'visible' : 'hidden',
      })
    },
  })

  const [setBgContainerSpring] = useSpring({
    config: defaultBackgroundSpringConfig,
    progress: 0,
    onUpdate: ({ progress }) => {
      panelBackgroundContainerStyle({
        z: (1 - progress) * -50,
        y: (1 - progress) * -50,
        transformPerspective: 800,
        transformOrigin: 'center',
        height: maxPanelHeightWithMarginForShadow,
      })
    },
  })

  useIsomorphicLayoutEffect(() => {
    const panelsAreOpen = delve(panelContextState, `${visiblePanel}`)
    const progress = panelsAreOpen ? 1 : 0
    const x = delve(panelContextState, `${visiblePanel}.content.width`, 0)

    // set gradient background to correct position without transition
    gsap.set(panelBackgroundGradientRef.current, {
      width: maxContentWidth,
      x: x - maxContentWidth,
    })

    setBgSpring({
      y: delve(panelContextState, `${visiblePanel}.wrapper.height`, 0),
      x,
      progress,
    })

    setBgContainerSpring({
      progress,
    })
  }, [visiblePanel])

  const idleHidePanel = useCallback(() => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(hidePanel)
    } else {
      hidePanel()
    }
  }, [hidePanel])

  const handleLogoRightClick = useCallback((e) => {
    e.preventDefault()
    setPopinIsOpen(true)
  }, [])

  const indices = [
    {
      label: 'Plugins',
      name: 'plugins',
    },
    {
      label: 'Providers',
      name: 'providers',
    },
    {
      label: 'Dev docs & User Docs',
      name: 'documentation',
    },
    {
      label: 'Blog Posts',
      name: 'blog_posts',
    },
    {
      label: 'Integrations',
      name: 'integrations',
    },
    {
      label: 'Partners',
      name: 'partners',
    },
  ]

  const memoizedPanels = useMemo(() => panels, [panels])

  return (
    <>
      <header
        className={cx(css.Desktop, className, isBannerOpen && css.banner, {
          dark: useDark,
          hasScrolled,
        })}
      >
        <HeaderBackground darkMode={darkMode} />
        {logoPopin && (
          <Popin visible={popinIsOpen} setVisible={setPopinIsOpen}>
            {({ close }) => <LogoPopin {...logoPopin} close={close} />}
          </Popin>
        )}
        <Layout type="maxWidth">
          <Layout className={css.nav} type="wrapper" tag="nav">
            <Logo
              className={isMarketplace ? css.marketplaceLogo : css.logo}
              dark={useDark}
              customLogo={customLogo}
              onContextMenu={(e) => handleLogoRightClick(e)}
              headerDarkMode={darkMode}
              hasScrolled={hasScrolled && !darkMode}
            />
            <div
              ref={panelBackgroundWrapperRef}
              className={css.panelBackgroundWrapper}
              style={{ height: maxPanelHeightWithMarginForShadow }}
            >
              <div
                className={css.panelBackgroundContainer}
                ref={panelBackgroundContainerRef}
              >
                <div className={css.panelBackground} ref={panelBackgroundRef}>
                  <div
                    className={css.panelBackgroundGradient}
                    ref={panelBackgroundGradientRef}
                  />
                </div>
              </div>
            </div>
            <ul className={css.links}>
              {memoizedPanels &&
                memoizedPanels.map((panel, id) => {
                  const isActive = visiblePanel === id
                  const withPanel =
                    panel.sections.length > 0 || panel.pushs.length > 0

                  const hasPrevPanel = delve(
                    panelContextState,
                    `${previousVisiblePanel}`
                  )
                  const hasCurrentPanel =
                    typeof visiblePanel === 'number' &&
                    panelContextState[visiblePanel]

                  let panelStatus

                  if (isActive) {
                    panelStatus = hasPrevPanel ? 'transition' : 'opening'
                  } else {
                    panelStatus = hasCurrentPanel ? 'transition' : 'closing'
                  }

                  const memoizedSections = useMemo(
                    () => panel.sections,
                    [panel.sections]
                  )
                  const memoizedArrowLinks = useMemo(
                    () => panel.arrowLinks,
                    [panel.arrowLinks]
                  )
                  const memoizedPushs = useMemo(
                    () => panel.pushs,
                    [panel.pushs]
                  )
                  const memoizedLowerLinks = useMemo(
                    () => lowerLinks,
                    [lowerLinks]
                  )

                  const handleMouseEnter = useCallback(
                    (index) => {
                      if (popinIsOpen) setPopinIsOpen(false)
                      setVisiblePanel(index)
                    },
                    [popinIsOpen]
                  )

                  return (
                    <NavigationLabel
                      key={id}
                      index={id}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={hidePanel}
                      dark={useDark}
                      isActive={isActive}
                      withPanel={withPanel}
                      label={panel.navigationLabel}
                      darkMode={darkMode}
                      hasScrolled={hasScrolled && !darkMode}
                    >
                      {withPanel && (
                        <Panel
                          sections={memoizedSections}
                          arrowLinks={memoizedArrowLinks}
                          pushs={memoizedPushs}
                          isVisible={isActive}
                          setPanelRect={setPanelRect}
                          panelId={id}
                          panelStatus={panelStatus}
                          onLinkClick={idleHidePanel}
                          lowerLinks={memoizedLowerLinks}
                          buttonIcons={panel.buttonIcons}
                        />
                      )}
                    </NavigationLabel>
                  )
                })}
            </ul>

            {!isMarketplace && !isBlank && (
              <div className={css.githubBtn}>
                <GithubButton
                  title="Star Strapi on GitHub"
                  githubStars={githubStars}
                  useDark={useDark}
                />
              </div>
            )}

            {!isBlank && (
              <div onClick={() => setSearching(true)} className={css.search}>
                <Search className={useDark ? css.iconLight : css.iconDark} />
              </div>
            )}

            {!isBlank && (
              <Button
                buttonClassName={css.buttonLink}
                className={css.button}
                theme={'white'}
                // theme={darkMode ? 'blue-500' : useDark ? 'lavender' : 'blue-500'}
                link={useMemo(
                  () => ({
                    href: 'https://strapi.io/contact-sales',
                    target: '_blank',
                  }),
                  []
                )}
              >
                Contact Sales
              </Button>
            )}

            {/* Growth Experiment */}
            {button && (
              <Button
                buttonClassName={css.buttonLink}
                className={css.button}
                theme={'blue-500'}
                // theme={darkMode ? 'blue-500' : useDark ? 'lavender' : 'blue-500'}
                link={button?.link}
              >
                {button?.link?.text}
              </Button>
            )}
          </Layout>
        </Layout>
      </header>
      {searching && (
        <SearchResults
          indices={indices}
          closePopin={() => setSearching(false)}
        />
      )}
    </>
  )
}

Desktop.defaultProps = {
  links: [],
}

export default Desktop
