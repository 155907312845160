import React from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import delve from 'dlv'
import Header from 'components/shared/Header'
import Footer from 'components/shared/Footer'
import Banner from 'components/shared/Banner'
import classnames from 'classnames/bind'

import {
  Seo,
  SeoProvider,
  Microdata,
  SponsoredScript,
} from 'components/shared/Seo'
import { useBannerContext } from '../BannerProvider'
import { useRouter } from 'next/router'
import useHubspotChat from 'src/hooks/useHubspotChat'
import css from './styles.module.scss'
import { useHasStickyBarProviderContext } from '../HasStickyBarProvider'

const cx = classnames.bind(css)

function Page({
  header,
  footer,
  logoPopin,
  settings,
  seo,
  children,
  pageSeo,
  forwardRef,
  darkMode,
  banner,
  githubStars,
}) {
  const { asPath } = useRouter()
  const dark = delve(settings, 'theme') !== 'light'
  const isBlank = delve(settings, 'blank')
  const customLogo = delve(settings, 'logo')
  const microdata = delve(settings, 'microdata')
  const sponsoredScript = delve(settings, 'sponsoredScript', false)
  const isMarketplace = delve(settings, 'isMarketplace', false)
  const isLaunchWeek = delve(settings, 'isLaunchWeek', false)

  const [hasStickyBar] = useHasStickyBarProviderContext()
  const [isBannerOpen, setIsBannerOpen] = useBannerContext()

  const checkBannerDisplay = () => {
    if (!banner) {
      return false
    }
    const store = JSON.parse(
      localStorage.getItem(process.env.BANNER_STORAGE_KEY)
    )
    const publishDate = Date.parse(banner.publishDate)

    if (store && store.date >= publishDate) {
      if (store.display) return true

      return false
    }

    const obj = {
      date: publishDate,
      display: true,
    }

    localStorage.setItem(process.env.BANNER_STORAGE_KEY, JSON.stringify(obj))

    return true
  }

  useIsomorphicLayoutEffect(() => {
    const bannerShouldDisplay = checkBannerDisplay()

    if (bannerShouldDisplay) {
      setTimeout(() => {
        setIsBannerOpen(true)
      }, 1000) // eliminate scroll bug, on haader
    }
  }, [])

  // Check if hubspot chat is available on each page change
  useHubspotChat()

  return (
    <SeoProvider defaultSeo={seo} pageSeo={pageSeo}>
      <div
        ref={forwardRef}
        className={cx(css.Page, {
          hasStickyBar,
          darkMode,
          cloudPage: asPath == '/cloud' ? true : false,
        })}
      >
        {/* Temporary condition to not override users custom SEO. See src/pages/users/[slug].js line 13. */}
        {children?.props?.userBlogPosts ? <></> : <Seo />}
        <Microdata />
        {microdata && <Microdata data={microdata} prepareStructure={false} />}

        {banner && isBannerOpen && <Banner banner={banner} />}

        {header && !isBlank && (
          <Header
            logoPopin={logoPopin}
            dark={dark}
            customLogo={customLogo}
            {...header}
            darkMode={darkMode}
            isMarketplace={isMarketplace}
            githubStars={githubStars}
            isLaunchWeek={isLaunchWeek}
            isBanner={!!banner && isBannerOpen}
          />
        )}

        {isBlank && (
          <Header
            logoPopin={logoPopin}
            dark={dark}
            darkMode={darkMode}
            githubStars={githubStars}
            isBlank={isBlank}
            isBanner={!!banner}
          />
        )}

        <main
          key={`page-${asPath}`}
          className={cx(
            !hasStickyBar && !isMarketplace && css.main,
            !!banner &&
              isBannerOpen &&
              (hasStickyBar ? css.bannerSticky : css.banner)
          )}
        >
          {sponsoredScript && <SponsoredScript />}
          {children}
        </main>

        {footer && !isBlank && asPath !== '/launch-week' && (
          <Footer {...footer} darkMode={darkMode} />
        )}
      </div>
    </SeoProvider>
  )
}

Page.defaultProps = {
  header: {},
  footer: {},
}

export default Page
