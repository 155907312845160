import React, { createRef } from 'react'
import { I18n } from 'react-polyglot'

import { GoogleTagManager } from '@next/third-parties/google'

import App from 'next/app'
import Error from 'next/error'
import { Poppins, Inter } from 'next/font/google'

import delve from 'dlv'
import * as Sentry from '@sentry/node'

import { initializeAmplitude } from '../lib/Amplitude/analytics'

import Viewport from 'services/viewport'
import fetchSingleType from 'src/lib/Strapi/fetchSingleType'

import Page from 'components/shared/Page'
import PrideMonthBanner from 'components/shared/PrideMonthBanner'
import HasScrolledProvider, {
  DetectHasScrolled,
} from 'components/shared/HasScrolledProvider'
import HasStickyBarProvider from 'components/shared/HasStickyBarProvider'
import { BannerProvider } from 'src/components/shared/BannerProvider'

import 'reset-css/sass/_reset.scss'
import '../cookiebot-overrides.scss'
import '../marketing-popins.scss'

import css from '../app.scss'
import { fetchGithubData } from 'src/lib/github'
import OrderConfirmation from './order-confirmation'
import GetLicense from './get-license'

const poppins = Poppins({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
})

const inter = Inter({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
})

if (typeof window === 'undefined') {
  const originalError = console.error
  console.error = (...args) => {
    if (/Warning: useLayoutEffect does nothing on the server/.test(args[0])) {
      return // Suppress the specific useLayoutEffect warning
    }
    originalError.call(console, ...args) // Allow other warnings to be shown
  }
}

if (process.env.SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Action timed out',
      '3000ms timeout exceeded',
      "undefined is not an object (evaluating 'window.HubSpotConversations.resetAndReloadWidget')",
      "Cannot read properties of null (reading 'readyState')",
    ],
  })
}

export default class CustomApp extends App {
  pageRef = createRef()

  componentDidMount() {
    const { amplitudeAPIKey } = this.props

    // Initialize Amplitude in production
    if (process.env.NODE_ENV === 'production') {
      initializeAmplitude(amplitudeAPIKey)
    } else {
      console.log('Amplitude not initialized - Non-production environment')
    }
  }

  onFontsLoaded = () => {
    Viewport.emit('resize')
  }

  mergeGlobalDataWithSlices(slices) {
    return slices.map((slice) => {
      switch (slice.__component) {
        case 'slices.newsletter-banner':
        case 'slices.socials-grid':
          if (slice.newsletter) return slice

          return { ...slice, newsletter: this.props.newsletter }
        default:
          return slice
      }
    })
  }

  hasStickyBar(route) {
    switch (route) {
      case '/blog':
      case '/blog/[slug]':
      case '/blog/categories/[slug]':
      case '/resource-center':
        return true
      default:
        return false
    }
  }

  render() {
    const {
      Component,
      logoPopin,
      header,
      footer,
      dictionary,
      newsletter,
      pageProps,
      seo,
      banner,
      prideMonth,
      router,
      githubStars,
    } = this.props

    const pageSettings = delve(pageProps, 'pageData.settings')
    const createdAt = delve(pageProps, 'pageData.created_at', '')
    const updatedAt = delve(pageProps, 'pageData.updated_at', '')
    const slices = delve(pageProps, 'pageData.slices')
    let pageSeo = pageProps?.pageData?.SEO
      ? delve(pageProps, 'pageData.SEO')
      : delve(pageProps, 'pageData.seo')
    const statusCode = delve(pageProps, 'statusCode')
    const darkMode = delve(pageProps, 'pageData.darkMode')

    if (slices) {
      pageProps.pageData.slices = this.mergeGlobalDataWithSlices(slices)
    }

    if (newsletter && pageProps.pageData) {
      pageProps.pageData.newsletter = newsletter
    }

    let dictionaryData

    if (dictionary) {
      dictionaryData =
        dictionary?.reduce((currentDictionary, keyValue) => {
          const entry =
            keyValue?.key && keyValue?.value
              ? { [keyValue?.key]: keyValue?.value }
              : {}

          return { ...currentDictionary, ...entry }
        }, {}) ?? null
    }

    return (
      <>
        <style jsx global>
          {`
            html {
              font-family: ${router.route.startsWith('/launch-week')
                ? inter.style.fontFamily
                : poppins.style.fontFamily};
            }
          `}
        </style>
        <GoogleTagManager gtmId="GTM-KN9JRWG" />
        <HasScrolledProvider>
          <HasStickyBarProvider
            initialHasStickyBar={this.hasStickyBar(router.route)}
          >
            <I18n locale="en" messages={dictionaryData} allowMissing>
              <div className={css.App}>
                <DetectHasScrolled />
                <BannerProvider>
                  {/* This condition is necessary to remove the navigation and footer
              from market.strapi.io, please do not remove it. */}
                  {router.route.startsWith('/marketplace') ? (
                    <>
                      {statusCode && statusCode === 404 ? (
                        <Error statusCode={statusCode} />
                      ) : (
                        <Page
                          githubStars={githubStars}
                          header={header}
                          logoPopin={logoPopin}
                          footer={footer}
                          banner={banner}
                          newsletter={newsletter}
                          settings={{
                            ...pageSettings,
                            logo: {
                              local: true,
                              media: { url: '/assets/marketplace_logo.svg' },
                              alt: 'Strapi Market logo',
                            },
                            isMarketplace: true,
                          }}
                          pageSeo={{
                            ...pageSeo,
                            createdAt,
                            updatedAt,
                          }}
                          seo={seo}
                          forwardRef={this.pageRef}
                          darkMode={darkMode}
                        >
                          <Component {...pageProps} />
                        </Page>
                      )}
                    </>
                  ) : router.route.startsWith('/launch-week') ? (
                    <>
                      {statusCode && statusCode === 404 ? (
                        <Error statusCode={statusCode} />
                      ) : (
                        <Page
                          githubStars={githubStars}
                          header={header}
                          banner={banner}
                          logoPopin={logoPopin}
                          footer={footer}
                          newsletter={newsletter}
                          settings={{
                            ...pageSettings,
                            logo: {
                              local: true,
                              media: { url: '/assets/strapi-logo-white.svg' },
                              alt: 'Strapi Market logo',
                            },
                            isLaunchWeek: true,
                          }}
                          pageSeo={{
                            ...pageSeo,
                            createdAt,
                            updatedAt,
                          }}
                          seo={seo}
                          forwardRef={this.pageRef}
                        >
                          <Component {...pageProps} />
                        </Page>
                      )}
                    </>
                  ) : router.route.startsWith('/order-confirmation') ? (
                    <OrderConfirmation />
                  ) : router.route.startsWith('/get-license') ? (
                    <GetLicense />
                  ) : (
                    <>
                      <Page
                        githubStars={githubStars}
                        header={header}
                        banner={banner}
                        logoPopin={logoPopin}
                        footer={footer}
                        newsletter={newsletter}
                        settings={pageSettings}
                        pageSeo={{
                          ...pageSeo,
                          createdAt,
                          updatedAt,
                        }}
                        seo={seo}
                        forwardRef={this.pageRef}
                        darkMode={darkMode}
                      >
                        {statusCode && statusCode === 404 ? (
                          <Error statusCode={statusCode} />
                        ) : (
                          <Component {...pageProps} />
                        )}
                      </Page>
                      {prideMonth && <PrideMonthBanner />}
                    </>
                  )}
                </BannerProvider>
              </div>
            </I18n>
          </HasStickyBarProvider>
        </HasScrolledProvider>
      </>
    )
  }
}

CustomApp.getInitialProps = async (context) => {
  const ctx = await App.getInitialProps(context)
  const amplitudeAPIKey = process.env.AMPLITUDE_WEBSITE_API_KEY

  // Helper function to fetch data with retry logic
  const fetchDataWithRetry = async (retry = true) => {
    const data = await fetchSingleType('global')

    // Check if header is defined in the data
    if (data?.header || !retry) {
      return data
    } else {
      // Log the retry attempt
      console.log(
        'Retrying to fetch global singleType due to getInitialProps issue...'
      )
      return fetchDataWithRetry(false) // Retry once
    }
  }

  // Fetch data with retry logic
  const data = await fetchDataWithRetry()
  const githubData = await fetchGithubData()
  const githubStars = githubData.stargazers_count
  const {
    header,
    footer,
    dictionary,
    newsletter,
    seo,
    metaTitleSuffix,
    banner,
    prideMonth,
    logoPopin,
  } = data || {} // Fallback to empty object if data is undefined

  return {
    ...ctx,
    header,
    footer,
    dictionary,
    newsletter,
    logoPopin,
    seo: {
      ...seo,
      metaTitleSuffix,
    },
    banner,
    prideMonth,
    githubStars,
    amplitudeAPIKey,
  }
}