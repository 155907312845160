import { useState, useEffect } from 'react'

const scriptCache = new Map()

function useScript({ src }) {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!src) return

    if (scriptCache.has(src)) {
      const cached = scriptCache.get(src)
      if (cached.loaded) {
        setLoaded(true)
        return
      }
      if (cached.error) {
        scriptCache.delete(src) // Force retry if previously failed
      }
    }

    // Remove any existing script (prevents duplicate scripts)
    document
      .querySelectorAll(`script[src^="${src}"]`)
      .forEach((s) => s.remove())

    const script = document.createElement('script')
    script.src = `${src}?t=${new Date().getTime()}` // Prevent caching
    script.async = true

    // Timeout Handling (force error if script takes too long)
    const timeout = setTimeout(() => {
      console.error('HubSpot script load timeout:', src)
      setError(true)
      scriptCache.set(src, { loaded: false, error: true })
    }, 10000) // 10 seconds timeout

    script.onload = () => {
      clearTimeout(timeout)
      setLoaded(true)
      scriptCache.set(src, { loaded: true, error: false })
    }

    script.onerror = (e) => {
      clearTimeout(timeout)
      setError(true)
      console.error('Script load error:', src, e)
      scriptCache.set(src, { loaded: false, error: true })
    }

    document.body.appendChild(script)

    return () => {
      script.remove()
      scriptCache.delete(src)
    }
  }, [src])

  return [loaded, error]
}

export default useScript
